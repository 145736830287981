/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  HeartIcon,
  HomeIcon,
  UserIcon,
  XIcon,
  MenuIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/outline'

import Logo from '../images/outlined-logo.png'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Navigation=(prop) =>{
  const [sidebarOpen, setSidebarOpen] = useState(false)
  
  let navigation = [
    { name: 'My Decks', href: '/', icon: HomeIcon, current: window.location.pathname == '/' },
  ]

  if(prop.email) {
    const userEmail=() => {
      return(<span>{prop.email}</span>)
    }

    navigation = [...navigation, ...[
      { name: 'Favorites', href: '/lists', icon: HeartIcon, current: window.location.pathname == '/lists' },
      { name: 'Manage Account', href: '/account', icon: UserIcon, current: window.location.pathname == '/account' },
      { name: 'Sign Out', href: '/users/sign_out', icon: XIcon, current: window.location.pathname == '/users/sign_out' },
    ]]
  }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="w-auto"
                    src={Logo}
                    alt="The Unstuck Box"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-usb-peach text-usb-orange'
                          : 'text-gray-400 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-2 py-2 text-base rounded-md'
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        stroke={item.current ? '#F67D6C' : '#9ca3af'}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="w-auto"
                src={Logo}
                alt="The Unstuck Box"
              />
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-usb-peach text-usb-orange' : 'text-gray-400 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-sm rounded-md'
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 flex-shrink-0 h-6 w-6'
                    )}
                    stroke={item.current ? '#F67D6C' : '#9ca3af'}
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white flex flex-row justify-between items-center">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="w-full flex flex-col items-center justify-center -ml-8">
            {prop.email ? (
              <span className="text-gray-800">{document.title}</span>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="157" height="19" viewBox="0 0 157 19" fill="none">
                <path d="M49.7794 11.8337C49.7794 15.1248 47.6201 16.2015 45.32 16.2015C43.0199 16.2015 40.8605 15.1248 40.8605 11.8337V3.57543L42.7691 3.36133V11.9377C42.7691 12.4393 42.8425 14.4213 45.32 14.4213C47.7975 14.4213 47.8709 12.4455 47.8709 11.9377V3.57543L49.7794 3.36133V11.8337Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M54.0979 15.8096L52.1893 16.0237V3L60.3926 11.8333V3.57502L62.3012 3.36092V16.4886L54.0979 7.65524V15.8034V15.8096Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M70.627 6.25445C70.2661 5.80178 69.532 5.04935 68.3636 5.04935C67.1035 5.04935 66.3327 5.80178 66.3327 6.77442C66.3327 7.92447 67.1585 8.21198 68.5104 8.76865C70.0948 9.41708 72.0707 10.1022 72.0707 12.543C72.0707 14.2681 70.8105 16.195 68.0211 16.195C66.2042 16.195 64.9074 15.3508 64.1366 14.1457L65.4335 12.8489C65.9718 13.7848 66.8894 14.4332 68.0394 14.4332C69.2629 14.4332 70.1621 13.7298 70.1621 12.6348C70.1621 11.1238 68.7429 10.8179 67.287 10.2796C66.0268 9.8147 64.4241 9.01946 64.4241 6.93347C64.4241 4.73737 66.1308 3.28146 68.3636 3.28146C70.1438 3.28146 71.2938 4.27246 71.8872 4.95148L70.627 6.24834V6.25445Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M72.462 5.33688L73.0187 3.5751H80.3044V5.33688H77.57V15.8035L75.6614 16.0176V5.33688H72.462Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M90.7958 11.8337C90.7958 15.1248 88.6364 16.2015 86.3363 16.2015C84.0362 16.2015 81.8768 15.1248 81.8768 11.8337V3.57543L83.7854 3.36133V11.9377C83.7854 12.4393 83.8588 14.4213 86.3363 14.4213C88.8138 14.4213 88.8872 12.4455 88.8872 11.9377V3.57543L90.7958 3.36133V11.8337Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M101.764 6.00351C101.171 5.56918 100.216 5.04921 98.9928 5.04921C96.2951 5.04921 94.3865 7.20861 94.3865 9.77786C94.3865 12.133 96.2767 14.4331 98.9561 14.4331C99.6779 14.4331 100.718 14.2373 101.746 13.4421L101.458 15.6749C100.706 15.9991 99.8186 16.1949 98.8154 16.1949C95.2735 16.1949 92.484 13.3503 92.484 9.71669C92.484 6.08303 95.2001 3.27521 98.9561 3.27521C100.198 3.27521 101.042 3.61777 101.458 3.79518L101.764 5.99127V6.00351Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M105.422 9.22734H105.533L110.39 3.16513L112.17 3.93591L107.655 9.34969L112.384 15.4302L110.549 16.1521L105.533 9.55156H105.422V15.8095L103.514 16.0236V3.57499L105.422 3.36088V9.22734Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M124.856 9.62489L124.685 9.56984L124.844 9.49031C125.884 8.95199 126.496 7.91206 126.496 6.71919C126.496 4.25393 124.165 3.37305 121.987 3.37305H118.941V16.1153H122.593C125.279 16.1153 127.01 14.7022 127.01 12.5245C127.01 11.1236 126.208 10.0409 124.863 9.62489H124.856ZM120.782 5.06753H122.342C123.768 5.06753 124.691 5.80772 124.691 6.94553C124.691 7.88759 123.951 8.83576 122.544 8.83576H120.782V5.06141V5.06753ZM122.624 14.4269H120.782V10.3895H122.807C124.416 10.3895 125.156 11.4356 125.156 12.4144C125.156 13.3931 124.373 14.4269 122.618 14.4269H122.624Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M135.513 3.07349C132.234 3.07349 128.686 5.62439 128.686 9.74131C128.686 13.4178 131.75 16.4152 135.513 16.4152C139.275 16.4152 142.333 13.4239 142.333 9.74131C142.333 5.62439 138.798 3.07349 135.513 3.07349ZM135.513 14.7208C132.766 14.7208 130.533 12.4941 130.533 9.76577C130.533 7.31275 132.399 4.76797 135.513 4.76797C136.913 4.76797 138.198 5.31241 139.122 6.3034C139.99 7.22711 140.486 8.48727 140.486 9.76577C140.486 12.4941 138.253 14.7208 135.513 14.7208Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M148.665 9.56344L148.696 9.5145L152.703 3.8805L151.076 3.1709L147.668 8.15035L144.224 3.1709L142.579 3.8805L146.634 9.55732L146.604 9.60626L142.187 15.7296L143.869 16.3842L147.656 11.0132L151.516 16.3842L153.217 15.7296L148.678 9.56344H148.665Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.78345 5.1165L4.36459 3.27521H11.9683V5.1165H9.1177V16.0419L7.12959 16.2683V5.1165H3.78345Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.59 3.049V8.65853H21.8602V3.27533L23.8483 3.049V16.0359L21.8602 16.2623V10.4998H15.59V16.0359L13.6019 16.2623V3.27533L15.59 3.049Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M33.4346 3.27521L33.8078 5.1165H28.345V8.66452H32.8718V10.5058H28.345V14.3169H34.2971L33.7711 16.1582H26.3569V3.27521H33.4346Z" fill="#F67D6C" stroke="#F67D6C" stroke-width="4.53273" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M49.7794 11.8337C49.7794 15.1248 47.6201 16.2015 45.32 16.2015C43.0199 16.2015 40.8605 15.1248 40.8605 11.8337V3.57543L42.7691 3.36133V11.9377C42.7691 12.4393 42.8425 14.4213 45.32 14.4213C47.7975 14.4213 47.8709 12.4455 47.8709 11.9377V3.57543L49.7794 3.36133V11.8337Z" fill="#F7F4EC"/>
                <path d="M54.0979 15.8096L52.1893 16.0237V3L60.3926 11.8333V3.57502L62.3012 3.36092V16.4886L54.0979 7.65524V15.8034V15.8096Z" fill="#F7F4EC"/>
                <path d="M70.627 6.25445C70.2661 5.80178 69.532 5.04935 68.3636 5.04935C67.1035 5.04935 66.3327 5.80178 66.3327 6.77442C66.3327 7.92447 67.1585 8.21198 68.5104 8.76865C70.0948 9.41708 72.0707 10.1022 72.0707 12.543C72.0707 14.2681 70.8105 16.195 68.0211 16.195C66.2042 16.195 64.9074 15.3508 64.1366 14.1457L65.4335 12.8489C65.9718 13.7848 66.8894 14.4332 68.0394 14.4332C69.2629 14.4332 70.1621 13.7298 70.1621 12.6348C70.1621 11.1238 68.7429 10.8179 67.287 10.2796C66.0268 9.8147 64.4241 9.01946 64.4241 6.93347C64.4241 4.73737 66.1308 3.28146 68.3636 3.28146C70.1438 3.28146 71.2938 4.27246 71.8872 4.95148L70.627 6.24834V6.25445Z" fill="#F7F4EC"/>
                <path d="M72.462 5.33688L73.0187 3.5751H80.3044V5.33688H77.57V15.8035L75.6614 16.0176V5.33688H72.462Z" fill="#F7F4EC"/>
                <path d="M90.7958 11.8337C90.7958 15.1248 88.6364 16.2015 86.3363 16.2015C84.0362 16.2015 81.8768 15.1248 81.8768 11.8337V3.57543L83.7854 3.36133V11.9377C83.7854 12.4393 83.8588 14.4213 86.3363 14.4213C88.8138 14.4213 88.8872 12.4455 88.8872 11.9377V3.57543L90.7958 3.36133V11.8337Z" fill="#F7F4EC"/>
                <path d="M101.764 6.00351C101.171 5.56918 100.216 5.04921 98.9928 5.04921C96.2951 5.04921 94.3865 7.20861 94.3865 9.77786C94.3865 12.133 96.2767 14.4331 98.9561 14.4331C99.6779 14.4331 100.718 14.2373 101.746 13.4421L101.458 15.6749C100.706 15.9991 99.8186 16.1949 98.8154 16.1949C95.2735 16.1949 92.484 13.3503 92.484 9.71669C92.484 6.08303 95.2001 3.27521 98.9561 3.27521C100.198 3.27521 101.042 3.61777 101.458 3.79518L101.764 5.99127V6.00351Z" fill="#F7F4EC"/>
                <path d="M105.422 9.22734H105.533L110.39 3.16513L112.17 3.93591L107.655 9.34969L112.384 15.4302L110.549 16.1521L105.533 9.55156H105.422V15.8095L103.514 16.0236V3.57499L105.422 3.36088V9.22734Z" fill="#F7F4EC"/>
                <path d="M124.856 9.62489L124.685 9.56984L124.844 9.49031C125.884 8.95199 126.496 7.91206 126.496 6.71919C126.496 4.25393 124.165 3.37305 121.987 3.37305H118.941V16.1153H122.593C125.279 16.1153 127.01 14.7022 127.01 12.5245C127.01 11.1236 126.208 10.0409 124.863 9.62489H124.856ZM120.782 5.06753H122.342C123.768 5.06753 124.691 5.80772 124.691 6.94553C124.691 7.88759 123.951 8.83576 122.544 8.83576H120.782V5.06141V5.06753ZM122.624 14.4269H120.782V10.3895H122.807C124.416 10.3895 125.156 11.4356 125.156 12.4144C125.156 13.3931 124.373 14.4269 122.618 14.4269H122.624Z" fill="#F7F4EC"/>
                <path d="M135.513 3.07349C132.234 3.07349 128.686 5.62439 128.686 9.74131C128.686 13.4178 131.75 16.4152 135.513 16.4152C139.275 16.4152 142.333 13.4239 142.333 9.74131C142.333 5.62439 138.798 3.07349 135.513 3.07349ZM135.513 14.7208C132.766 14.7208 130.533 12.4941 130.533 9.76577C130.533 7.31275 132.399 4.76797 135.513 4.76797C136.913 4.76797 138.198 5.31241 139.122 6.3034C139.99 7.22711 140.486 8.48727 140.486 9.76577C140.486 12.4941 138.253 14.7208 135.513 14.7208Z" fill="#F7F4EC"/>
                <path d="M148.665 9.56344L148.696 9.5145L152.703 3.8805L151.076 3.1709L147.668 8.15035L144.224 3.1709L142.579 3.8805L146.634 9.55732L146.604 9.60626L142.187 15.7296L143.869 16.3842L147.656 11.0132L151.516 16.3842L153.217 15.7296L148.678 9.56344H148.665Z" fill="#F7F4EC"/>
                <path d="M3.78345 5.1165L4.36459 3.27521H11.9683V5.1165H9.1177V16.0419L7.12959 16.2683V5.1165H3.78345Z" fill="#F7F4EC"/>
                <path d="M15.59 3.049V8.65853H21.8602V3.27533L23.8483 3.049V16.0359L21.8602 16.2623V10.4998H15.59V16.0359L13.6019 16.2623V3.27533L15.59 3.049Z" fill="#F7F4EC"/>
                <path d="M33.4346 3.27521L33.8078 5.1165H28.345V8.66452H32.8718V10.5058H28.345V14.3169H34.2971L33.7711 16.1582H26.3569V3.27521H33.4346Z" fill="#F7F4EC"/>
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
