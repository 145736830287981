import React from 'react'

const Card = ({ card, chapterColor, isFavorite, onFavoriteClick }) => {
    console.log('Returning card')
  return (
    <div 
      className="card"
      style={{
        borderColor: chapterColor,
        background: 'linear-gradient(159deg, #FFF 8.59%, rgba(255, 255, 255, 0.80) 95.77%)',
        padding: '20px',
        borderRadius: '20px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        margin: '0px',
        height: '100%'
      }}
    >
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>
          <h3 style={{ marginBottom: '10px', textAlign: 'center', fontWeight: 'bold' }}>{card.title}</h3>
          <p style={{ fontSize: '16px', textAlign: 'center' }}>{card.card_text}</p>
          <p style={{ fontSize: '14px', fontStyle: 'italic', marginTop: '10px', textAlign: 'center' }}>{card.note}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <button
            className="favorite-button"
            onClick={onFavoriteClick}
            style={{
              backgroundColor: 'white',
              border: 'none',
              borderRadius: '50%',
              width: '50px',
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '3px 4px 16px 0px #0000000D',
              cursor: 'pointer',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={isFavorite ? "#eb4d59" : "none"}
              stroke={isFavorite ? "#eb4d59" : "#999999"}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Card