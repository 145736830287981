import { Controller } from "@hotwired/stimulus"
import React from 'react'
import { createRoot } from 'react-dom/client'
import CardCarousel from '../components/CardCarousel'

export default class extends Controller {
  connect() {
    const root = createRoot(this.element)
    root.render(
      <CardCarousel
        cards={JSON.parse(this.element.dataset.cards)}
        favorites={JSON.parse(this.element.dataset.favorites)}
        chapterColor={this.element.dataset.chapterColor}
      />
    )
  }
}