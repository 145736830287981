import React, { useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Card from './Card'

const CardCarousel = ({ cards, favorites: initialFavorites, chapterColor }) => {
  const [favorites, setFavorites] = useState(initialFavorites)

  const saveToFavorite = (cardId) => {
    console.log('saved to favourites!')
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
      body: JSON.stringify({ card_data: { card_id: cardId } })
    }

    fetch('/add_to_list', requestOptions)
      .then(response => response.json())
      .then(data => {
        setFavorites(data)
      });
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 10,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30
    }
  }

  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      showDots={false}
      infinite={true}
      keyBoardControl={true}
      itemClass="carouselItem"
      slidesToSlide={1}
      removeArrowOnDeviceType={["mobile"]}
      partialVisible={true}
    >
      {cards && cards.length > 0 ? (
        cards.map((card, index) => (
          <Card 
            key={index} 
            card={card} 
            chapterColor={chapterColor}
            isFavorite={favorites.includes(card.id)}
            onFavoriteClick={() => saveToFavorite(card.id)}
          />
        ))
      ) : (
        <div>No cards available</div>
      )}
    </Carousel>
  )
}

export default CardCarousel